import * as React from "react"

import Layout from "../components/layout"
import Wrapper from "../components/reusable/Wrapper"
import Seo from "../components/Seo"
import Button from "../components/reusable/Button"

import img404 from "../images/404.png"

const meta = {
  ogTitle: "404 Error | Mitchells & Butlers",
  ogDescription: "Well, this is a bit embarassing, we can't seem to find that page at the moment.",
  twitterTitle: "404 Error | Mitchells & Butlers",
  twitterDescription: "Well, this is a bit embarassing, we can't seem to find that page at the moment.",
}

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Error | Mitchells & Butlers" description="404 Error. Sorry, we can't seem to find that page. Let's head back home where you'll find plenty of content to explore." meta={meta} />
    <div className="page-404">
      <Wrapper>
        <img src={img404} alt="404" />
        <div className="con-wrap">
          <h1>Whoops!</h1>
          <p>
            <strong>Service to table 404, please....</strong>
            <br />
            Let's take you back to the home page while we clean up.
          </p>
          <Button
            link="/"
            text="Back To Homepage"
            buttonStyle="primary"
            colour="white"
          />
        </div>
      </Wrapper>
    </div>
  </Layout>
)

export default NotFoundPage
